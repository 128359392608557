// @ts-nocheck

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ListsWidgetStatistical, ChartsWidgetStatistical} from '../../../_metronic/partials/widgets'
import 'animate.css'
import axios from 'axios'

const AlertStatus: FC = () => {
  const [status, setStatus] = useState('')
  useEffect(() => {
    ;(async () => {
      try {
        const response = await axios.get('https://acm.haiphatland.com.vn/api/AbpHpl/Dashboard')
        if (response.status === 200) {
          setStatus(response.data[0].serverStatus)
        }
      } catch (error) {
        if (error) console.log(error.response)
      }
    })()
  }, [])
  // const isDanger = `alert mb-5 p-5 animate__animated animate__backInRight ${
  //   status === false ? 'alert-danger' : 'alert-success'
  // }`
  return (
   
    <>
      {status === false && (
        <div class='alert mb-5 p-5 animate__animated animate__backInRight' role='alert'>
          <h4 class='alert-heading'>Thông báo</h4>
          <p>
          Phần mềm server không hoạt động, các chức năng chính của Tool sẽ không hoạt động
          </p>
          <div class='border-bottom border-white opacity-20 mb-5'></div>
        </div>
      )}
    </>
  )
}
const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidgetStatistical className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <ChartsWidgetStatistical className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}

    {/* end::Row */}

    {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <AlertStatus />
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
