/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import axios from 'axios'


type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidgetUpdate2: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [getValue, setGetValue] = useState(0)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    ;(async () => {
      try {
        const response = await axios.get('https://acm.haiphatland.com.vn/api/employee/FlattenAllHplPhongBan')

        if (response.status === 200) {
          setGetValue(response.data)

          chart.updateOptions({
            series: [getValue],

            noData: {
              text: 'Loading',
            },
          })
        }
      } catch (error: any) {
        if (error) console.log(error.response)
      }
    })()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, count])

  const updateAPI = () =>{
    ;(async () => {
        try {
          const response = await axios.get('https://acm.haiphatland.com.vn/api/employee/FlattenAllHplPhongBan')
  
          if (response.status === 200) {
            setCount(count + 1)
            setGetValue(response.data)
  
            
          }
        } catch (error: any) {
          if (error) console.log(error.response)
        }
      })()
  }
console.log(getValue)
  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Flatten Phòng ban</span>
          
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>

        <div className='pt-5'>
          <p className='text-center fs-6 pb-5 '>
            <span className='badge badge-light-danger fs-8'>Notes:</span>&nbsp; Xóa một Mã Phòng ban trong bảng Flatten
          </p>

          <a className={`btn btn-${chartColor} w-100 py-3`} onClick={updateAPI}>
            Cập nhật
          </a>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {MixedWidgetUpdate2}
