// @ts-nocheck

/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import axios from 'axios'

type Props = {
  className: string
}

const ChartsWidgetStatistical: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [isData, setIsData] = useState(false)
  const [count, setCount] = useState(0)
  const [dataChart, setDataChart] = useState([])
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    ;(async () => {
      try {
        const response = await axios.get('https://acm.haiphatland.com.vn/api/AbpHpl/Dashboard')

        if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
          // console.log(response.data)
          // setDataChart(response.[0].thongKeTheoThangUser)
          const reverseData = response.data[0].thongKeTheoThangUser.reverse()

          chart.updateOptions({
            series: [
              {
                name: 'User đã tạo',
                data: reverseData.map((item) => item.totalNew),
              },
              {
                name: 'User đã disable',
                data: reverseData.map((item) => item.totalDis),
              },
            ],
            xaxis: {
              categories: reverseData.map((item) => item.thang),
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            noData: {
              text: 'Loading',
            },
          })
        }
      } catch (error: any) {
        if (error) console.log(error.response)
      }
    })()

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart: any = new ApexCharts(chartRef.current, getChartOptions(height))

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            Thống kê số lượng user đã tạo và disable theo tháng
          </span>

          <span className='text-muted fw-bold fs-7'>More than 500 new orders</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          {/* <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_2_year_btn'
          >
            Year
          </a> */}

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_2_month_btn'
          >
            Month
          </a>

          {/* <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_2_week_btn'
          >
            Week
          </a> */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidgetStatistical}

function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-warning')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'User đã tạo',
        data: [],
      },
      {
        name: 'User đã disable',
        data: [],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' user'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
