// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react'
import {KTSVG} from '../../../helpers'

import axios from 'axios'

type Props = {
  className: string
}

const ListsWidgetStatistical: React.FC<Props> = ({className}) => {
  const [countDuplicate, setCoundDuplicate] = useState([])
  const [loading, setLoading] = useState(false)
  const [isData, setIsData] = useState(false)
  const originalResult = useRef([])
  useEffect(() => {
    ;(async () => {
      try {
        const response = await axios.get('https://acm.haiphatland.com.vn/api/AbpHpl/Dashboard')

        if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
          setCoundDuplicate(response.data[0].trungThongTinHrm)
        }
      } catch (error) {
        if (error) console.log(error.response)
      }
    })()
  }, [])

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Thông báo</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {countDuplicate?.map((item, index) => {
        return (
          <div className='card-body pt-0' key={index}>
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-warning me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                  TỔNG nhân sự trùng mã nhân viên
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bolder text-warning py-1'>{item.trungMaNhanVien}</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-success me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                  TỔNG nhân sự trùng Username
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bolder text-success py-1'>{item.trungUser}</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}

            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-danger me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                  TỔNG nhân sự trùng Email
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bolder text-danger py-1'>{item.trungEmail}</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}

            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-primary rounded p-5 mb-7'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-primary me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                  TỔNG nhân sự trùng CMND
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bolder text-danger py-1'>{item.trungCmnd}</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-info rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-info me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                  TỔNG nhân sự trùng số điện thoại
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bolder text-info py-1'>{item.trungDienThoai}</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
        )
      })}

      {/* end::Body */}
    </div>
  )
}

export {ListsWidgetStatistical}
